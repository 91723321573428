export default [
  {
    key: "draft",
  },
  {
    key: "pending",
  },
  {
    key: "reviewed",
  },
  {
    key: "approved",
  },
  {
    key: "rejected",
  }
];