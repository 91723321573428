export default [
  {
    value: "Ciencias de la Salud", // temporally
    key: "ch2_1_1",
  },
  {
    value: "Ciencias Humanas y Sociales", // temporally
    key: "ch2_1_2",
  },
  {
    value: "Ciencias de la Vida", // temporally
    key: "ch2_1_3",
  },
  {
    value: "Ciencias Exactas", // temporally
    key: "ch2_1_4",
  },
  {
    value: "Ciencias del Universo", // temporally
    key: "ch2_1_5",
  },
];