import FormEs2022 from "@/components/announcenments/A-FORM-2022/form-es"; 
import FormFr2022 from "@/components/announcenments/A-FORM-2022/form-fr"; 

import FormEs2023 from "@/components/announcenments/A-FORM-2023/form-es"; 
import FormFr2023 from "@/components/announcenments/A-FORM-2023/form-fr"; 

export const FormAnnouncementInput = {
    // Announcement 2022
    "A-FORM-2022-es": FormEs2022,
    "A-FORM-2022-fr": FormFr2022,

    // Announcement 2023
    "A-FORM-2023-es": FormEs2023,
    "A-FORM-2023-fr": FormFr2023,
}