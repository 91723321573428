<template>
  <div class="wrapper__main-form py-10 px-10" v-if="getVersion">
    <div class="form-steps">
      <v-row>
        <v-col cols="12" sm="12" md="12" v-if="isDisableFormEditmAll">
          <v-alert border="left" colored-border text color="error">
            IMPORTANT! Pour voir en temps réel tous les champs remplis par votre contrepartie argentine vous devez « actualiser/rafraîchir » l’écran de votre navigateur.
          </v-alert>
        </v-col>
      </v-row>
      <v-stepper v-model="activeStep" vertical class="custom-header">
        <!-- Step 1 -->
        <v-stepper-step
          :complete="stepValidationResult[1]"
          step="1"
          ref="step1"
          :rules="[() => stepValidationResult[1]]"
          :class="{ 'active-step': activeStep === 2 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(1)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
            ></span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Elementos necesarios para la presentación</span
              >
              <span class="text--secondary text-xs"
                >Para el equipo Argentino</span
              >
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="1" step="1">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep1"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12">
                <div class="subtitle-1">
                  <strong>
                    1. Director del Proyecto: miembro CIC-CONICET que debe
                    revestir la categoría de investigador asistente con más de 3
                    años de desde fecha de ingreso y dos informes aprobados (o
                    equivalente en otros organismos científicos y
                    académicos).</strong
                  >
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Indicar categoría"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f1_1_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f1_1_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Indicar organismo al que pertenece"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f1_1_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f1_1_2')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="subtitle-1">
                  <strong>
                    2. Al menos uno de los miembros del equipo de trabajo
                    argentino tiene que haber finalizado la supervisión de una
                    tesis doctoral.
                  </strong>
                </div>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="text"
                  label="Indicar el nombre del investigador que cumple la condición"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f1_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f1_2')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5">
                <div class="subtitle-1">
                  <strong
                    >3. Formación de recursos humanos: estudiantes de Doctorado
                    y/o Post-doctorado asociados al proyecto:
                  </strong>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="subtitle-1">
                  El equipo argentino cuenta con estudiantes de doctorado o
                  becario postdoctoral (Al menos un estudiante de doctorado o un
                  post-doc dirigido por miembros del equipo de trabajo)
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-text-field
                  type="text"
                  label="Indicar nombre"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f1_3_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f1_3_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-text-field
                  type="text"
                  label="Indicar categoría"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f1_3_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f1_3_2')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-text-field
                  type="text"
                  label="Indicar director de investigación"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f1_3_3')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f1_3_3')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="subtitle-1">
                  <strong>
                    4. Financiamiento propio del proyecto (con excepciòn de
                    proyectos en el àrea de SHS)
                  </strong>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Indicar tipo de subsidio/s "
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f1_4_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f1_4_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Indicar importe financiado: Importe en pesos/3 años"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f1_4_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f1_4_2')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              color="primary"
              @click="onStepNext(2)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 2 -->
        <v-stepper-step
          :complete="stepValidationResult[2]"
          ref="step2"
          step="2"
          :rules="[() => stepValidationResult[2]]"
          :class="{ 'active-step': activeStep === 2 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(2)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
            ></span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Convocatoria y Guia de buenas practicas</span
              >
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="2" step="2">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep2"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12">
                <ConvocatoriaEs />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  label="He leido y acepto todas las condiciones"
                  :input-value="getFormValues('ch1_1')"
                  @change="(value) => onInputField(value, 'ch1_1')"
                  :rules="[validators.isSelected]"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(3)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 3 -->
        <v-stepper-step
          :complete="stepValidationResult[3]"
          ref="step3"
          step="3"
          :rules="[() => stepValidationResult[3]]"
          :class="{ 'active-step': activeStep === 3 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(3)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >01</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Título del proyecto</span
              >
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="3" step="3">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep3"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12">
                <div class="subtitle-1">Título del proyecto:</div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f2_1', projectLocale.name)"
                  :rules="[validators.required]"
                  @change="(value) => onUpdateFieldProject(value, 'name_es')"
                  @input="(value) => onInputField(value, 'f2_1')"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <div class="subtitle-1">Palabras clave (4 máximo):</div>
                <v-text-field
                  type="text"
                  label=""
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f2_2')"
                  :rules="[validators.required]"
                  @change="(value) => onUpdateFieldProject(value, 'tags')"
                  @input="(value) => onInputField(value, 'f2_2')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="subtitle-1">
                  Disciplina (marcar según corresponda):
                </div>
                <v-checkbox
                  v-for="(category, index) in categoriesVersion"
                  :key="index"
                  :label="t(category.key)"
                  ref="categoriesProject"
                  :input-value="getFormValues(category.key)"
                  @change="
                    (value) => {
                      onInputField(value && true, category.key);
                      onUpdateFieldCategoriesProject();
                    }
                  "
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(4)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 4 -->
        <v-stepper-step
          :complete="stepValidationResult[4]"
          ref="step4"
          step="4"
          :rules="[() => stepValidationResult[4]]"
          :class="{ 'active-step': activeStep === 4 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(4)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >02</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Institución principal</span
              >
              <span class="text--secondary text-xs"
                >Al cual pertenece el responsable científico del proyecto
              </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="4" step="4">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep4"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" class="mt-5"> <h3>En Francia:</h3></v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="*Laboratorio/CENTRO (o equipo) "
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f3_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f3_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nombre del/de la directora/a:"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f3_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f3_2')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-5"
                ><div class="subtitle-1">
                  *Indicar el status de la Unidad: UPRES, EA, UMR, UPR, U.
                  INSERM, U. INRA, etc. (universidad, centro de investigación ?)
                </div></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5"> <h3>En Argentina:</h3></v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Laboratorio/CENTRO (o equipo) "
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f3_3')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f3_3')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nombre del/de la directora/a:"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f3_4')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f3_4')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Para considerar completa la presentación, deberá enviarse la <a href="https://docs.google.com/document/d/18gLQo_1Aw5b_-L2f6Oct8GFDFh7ol02G/edit?usp=sharing&ouid=101368365551661249652&rtpof=true&sd=true" target="_blank"> Planilla Institucional </a> completa y firmada a convocatoriasinternacionales@mincyt.gob.ar hasta el 31/5/2022
                </div>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(5)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 5 -->
        <v-stepper-step
          :complete="stepValidationResult[5]"
          ref="step5"
          step="5"
          :rules="[() => stepValidationResult[5]]"
          :class="{ 'active-step': activeStep === 5 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(5)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >03</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Responsables del proyecto</span
              >
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="5" step="5">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep5"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" class="mt-5"> <h3>*En Francia:</h3></v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nombre y apellido"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_1_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f4_1_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Categoría"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_1_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f4_1_2')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Domicilio administrativo"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_1_3')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f4_1_3')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <AnnouncementFileInput
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :name-key="'a4_1'"
                  label="CV de Responsable en Francia. Adjuntar Anexo pdf Menor a 2MB"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Teléfono"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_1_4')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f4_1_4')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Correo electrónico"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_1_5')"
                  :rules="[validators.required, validators.emailValidator]"
                  @input="(value) => onInputField(value, 'f4_1_5')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-5"
                ><div class="subtitle-1">
                  *El investigador francés responsable del proyecto debe estar
                  habilitado a dirigir investigadores. Un joven investigador no
                  titulado con un HDR puede, sin embargo, codirigir el proyecto,
                  con la condición de que al menos un miembro de su equipo,
                  titular del HDR, esté implicado en la formación de los
                  doctorandos
                </div></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5"> <h3>En Argentina:</h3></v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nombre y apellido"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_2_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f4_2_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Categoría"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_2_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f4_2_2')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Domicilio administrativo"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_2_3')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f4_2_3')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <AnnouncementFileInput
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :name-key="'a4_2'"
                  label="CV de Responsable en Argentina. Adjuntar Anexo pdf Menor a 2MB"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Teléfono"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_2_4')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f4_2_4')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Correo electrónico"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_2_5')"
                  :rules="[validators.required, validators.emailValidator]"
                  @input="(value) => onInputField(value, 'f4_2_5')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(6)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 6 -->
        <v-stepper-step
          :complete="stepValidationResult[6]"
          ref="step6"
          step="6"
          :rules="[() => stepValidationResult[6]]"
          :class="{ 'active-step': activeStep === 6 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(6)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >04</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Lista de investigadores participantes
              </span>
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="6" step="6">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep6"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" class="mt-5">
                <div class="subtitle-1">
                  Lista de investigadores/as participantes (categoría e
                  institución a la que pertenecen) y becarios/as (categoría e
                  institución a la que pertenecen) participantes del proyecto
                  (si es necesario, identificar investigadores/as responsables,
                  beneficiarios/as de las movilidades, y los/as
                  investigadores/as asociados/as u ocasionales). Notar que 2
                  investigadores/as estatutarios/as o permanentes en cada equipo
                  es el mínimo de integrantes, pero dependiendo de la cantidad
                  de estudiantes de doctorado o post doc, una cantidad de hasta
                  3 o 4 investigadores/as principales puede ser razonable para
                  cada equipo de trabajo.
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5"> <h3>En Francia:</h3></v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f5_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f5_1')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5"> <h3>En Argentina:</h3></v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f5_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f5_2')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(7)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 7 -->
        <v-stepper-step
          :complete="stepValidationResult[7]"
          ref="step7"
          step="7"
          :rules="[() => stepValidationResult[7]]"
          :class="{ 'active-step': activeStep === 7 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(7)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >05</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Resumen científico</span
              >
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="7" step="7">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep7"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" class="mt-5">
                <div class="subtitle-1">
                  Resumen científico para divulgación y difusión del proyecto
                  (francés y castellano - opcional inglés). Si el proyecto es
                  beneficiado con la financiación, estos resúmenes serán
                  publicados en esta plataforma y en el sitio ECOS-Sud y MinCyT.
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  type="text"
                  label="Francés"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f6_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f6_1')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  type="text"
                  label="Castellano"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f6_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f6_2')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  type="text"
                  label="Inglés"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f6_3')"
                  @input="(value) => onInputField(value, 'f6_3')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(8)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 8 -->
        <v-stepper-step
          :complete="stepValidationResult[8]"
          ref="step8"
          step="8"
          :rules="[() => stepValidationResult[8]]"
          :class="{ 'active-step': activeStep === 8 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(8)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >06</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Descripción del proyecto científico
              </span>
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="8" step="8">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep8"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" class="mt-5">
                <h3>Descripción del proyecto científico.</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Problemática y contexto bibliográfico (9.000 caracteres max.)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  rows="20"
                  maxlength="9000"
                  hide-details="auto"
                  :value="getFormValues('f7_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f7_1')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Objetivos e hipótesis (3.000 caracteres max.)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  maxlength="3000"
                  hide-details="auto"
                  :value="getFormValues('f7_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f7_2')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Metodología y plan de trabajo (6.000 caracteres max.)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  rows="15"
                  maxlength="6000"
                  hide-details="auto"
                  :value="getFormValues('f7_3')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f7_3')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Participación de cada equipo y valor agregado en la
                  colaboración (3.000 caracteres max.)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  maxlength="3000"
                  hide-details="auto"
                  :value="getFormValues('f7_4')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f7_4')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Resultados esperados (3.000 caracteres max)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  maxlength="3000"
                  hide-details="auto"
                  :value="getFormValues('f7_5')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f7_5')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(9)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 9 -->
        <v-stepper-step
          :complete="stepValidationResult[9]"
          ref="step9"
          step="9"
          :rules="[() => stepValidationResult[9]]"
          :class="{ 'active-step': activeStep === 9 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(9)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >07</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Plan de formación de los doctorandos
              </span>
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="9" step="9">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep9"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Incluir una descripción breve de los objetivos generales y
                  específicos del plan de trabajo doctoral de cada estudiante de
                  doctorado. (Máximo 3000 caracteres)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  rows="15"
                  maxlength="3000"
                  hide-details="auto"
                  :value="getFormValues('f8_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f8_1')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(10)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 10 -->
        <v-stepper-step
          :complete="stepValidationResult[10]"
          ref="step10"
          step="10"
          :rules="[() => stepValidationResult[10]]"
          :class="{ 'active-step': activeStep === 10 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(10)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >08</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Misiones para el primer año
              </span>
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="10" step="10">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep10"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" class="mt-5">
                <div class="subtitle-1">
                  Recursos solicitados para la realización de misiones de
                  investigación y/o formación de recursos humanos para el primer
                  año de ejecución del proyecto
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5">
                <h3>DESDE ARGENTINA HACIA FRANCIA</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <v-select
                  :items="['Investigación', 'Pasantía']"
                  label="Tipo de misión"
                  outlined
                  :value="getFormValues('s9_1_1')"
                  :rules="[validators.required]"
                  @change="(value) => onInputField(value, 's9_1_1')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nombre y apellido del/de la beneficiario/a: "
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f9_1_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_1_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-text-field
                  v-if="getFormValues('s9_1_1') === 'Pasantía'"
                  type="number"
                  label="Duración (30 días)"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f9_1_2_a')"
                  :rules="[validators.required, v => ( v && v >= 30 ) || '']"
                  @input="(value) => onInputField(value, 'f9_1_2_a')"
                ></v-text-field>
                <v-text-field
                  v-if="getFormValues('s9_1_1') === 'Investigación'"
                  type="number"
                  label="Duración (14 días)"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f9_1_2_b')"
                  :rules="[validators.required, v => ( v && v >= 14 ) || '']"
                  @input="(value) => onInputField(value, 'f9_1_2_b')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Justificación detallada de la necesidad de la misión en
                  relación al proyecto y plan de trabajo (Máximo 800 caracteres)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  maxlength="800"
                  hide-details="auto"
                  :value="getFormValues('f9_1_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_1_2')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <v-select
                  :items="['Investigación', 'Pasantía']"
                  label="Tipo de misión"
                  outlined
                  :value="getFormValues('s9_2_1')"
                  :rules="[validators.required]"
                  @change="(value) => onInputField(value, 's9_2_1')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nombre y apellido del/de la beneficiario/a: "
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f9_2_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_2_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-text-field
                  v-if="getFormValues('s9_2_1') === 'Pasantía'"
                  type="number"
                  label="Duración (30 días)"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f9_2_2_a')"
                  :rules="[validators.required, v => ( v && v >= 30 ) || '']"
                  @input="(value) => onInputField(value, 'f9_2_2_a')"
                ></v-text-field>
                <v-text-field
                  v-if="getFormValues('s9_2_1') === 'Investigación'"
                  type="number"
                  label="Duración (14 días)"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f9_2_2_b')"
                  :rules="[validators.required, v => ( v && v >= 14 ) || '']"
                  @input="(value) => onInputField(value, 'f9_2_2_b')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Justificación detallada de la necesidad de la misión en
                  relación al proyecto y plan de trabajo (Máximo 800 caracteres)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  maxlength="800"
                  hide-details="auto"
                  :value="getFormValues('f9_2_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_2_2')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5">
                <h3>DESDE FRANCIA HACIA ARGENTINA</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <v-select
                  :items="['Investigación', 'Pasantía']"
                  label="Tipo de misión"
                  outlined
                  :value="getFormValues('s9_3_1')"
                  :rules="[validators.required]"
                  @change="(value) => onInputField(value, 's9_3_1')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nombre y apellido del/de la beneficiario/a: "
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f9_3_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_3_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-text-field
                  v-if="getFormValues('s9_3_1') === 'Pasantía'"
                  type="number"
                  label="Duración (Al menos 14 días)"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f9_3_2_a')"
                  :rules="[validators.required, v => ( v && v >= 14 ) || '']"
                  @input="(value) => onInputField(value, 'f9_3_2_a')"
                ></v-text-field>
                <v-text-field
                  v-if="getFormValues('s9_3_1') === 'Investigación'"
                  type="number"
                  label="Duración (Al menos 30 días)"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f9_3_2_b')"
                  :rules="[validators.required, v => ( v && v >= 30 ) || '']"
                  @input="(value) => onInputField(value, 'f9_3_2_b')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Justificación detallada de la necesidad de la misión en
                  relación al proyecto y plan de trabajo (Máximo 800 caracteres)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  maxlength="800"
                  hide-details="auto"
                  :value="getFormValues('f9_3_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_3_2')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <v-select
                  :items="['Investigación', 'Pasantía']"
                  label="Tipo de misión"
                  outlined
                  :value="getFormValues('s9_4_1')"
                  :rules="[validators.required]"
                  @change="(value) => onInputField(value, 's9_4_1')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nombre y apellido del/de la beneficiario/a: "
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f9_4_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_4_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-text-field
                  v-if="getFormValues('s9_4_1') === 'Pasantía'"
                  type="number"
                  label="Duración (Al menos 14 días)"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f9_4_2_a')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_4_2_a')"
                ></v-text-field>
                <v-text-field
                  v-if="getFormValues('s9_4_1') === 'Investigación'"
                  type="number"
                  label="Duración (Al menos 30 días)"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f9_4_2_b')"
                  :rules="[validators.required, v => ( v && v >= 30 ) || '']"
                  @input="(value) => onInputField(value, 'f9_4_2_b')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Justificación detallada de la necesidad de la misión en
                  relación al proyecto y plan de trabajo (Máximo 800 caracteres)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  maxlength="800"
                  hide-details="auto"
                  :value="getFormValues('f9_4_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_4_2')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(11)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 11 -->
        <v-stepper-step
          :complete="stepValidationResult[11]"
          ref="step11"
          step="11"
          :rules="[() => stepValidationResult[11]]"
          :class="{ 'active-step': activeStep === 11 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(11)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >09</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Antecedentes de cooperación con la parte Francesa
              </span>
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="11" step="11">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep11"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Antecedentes de cooperación con la parte argentina (adjuntar
                  en anexo, en caso que corresponda, las referencias de
                  publicaciones conjuntas, tesis, proyectos conjuntos, patentes,
                  etc.). Este apartado obligatoriamente deberá mencionar los
                  proyectos anteriores a ECOS relevantes a esta cooperación.
                  (3.000 caracteres)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  rows="15"
                  maxlength="3000"
                  hide-details="auto"
                  :value="getFormValues('f10_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f10_1')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <AnnouncementFileInput
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :name-key="'a10_1'"
                  label="Adjuntar Anexo pdf Menor a 2MB"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <AnnouncementFileInput
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :name-key="'a10_2'"
                  label="Adjuntar Anexo pdf Menor a 2MB"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <AnnouncementFileInput
                  :required="false"
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :name-key="'a10_3'"
                  label="Adjuntar Anexo pdf Menor a 2MB"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <AnnouncementFileInput
                  :required="false"
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :name-key="'a10_4'"
                  label="Adjuntar Anexo pdf Menor a 2MB"
                />
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(12)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 12 -->
        <v-stepper-step
          :complete="stepValidationResult[12]"
          ref="step12"
          step="12"
          :rules="[() => stepValidationResult[12]]"
          :class="{ 'active-step': activeStep === 12 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(12)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >10</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Recursos</span
              >
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="12" step="12">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep12"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  <strong>
                    Financiación relevante para el proyecto proveniente de otras
                    fuentes de financiamiento </strong
                  >(Es OBLIGATORIO detallar las distintas fuentes de
                  financiación de cualquier origen incluido el sector
                  industrial, institutos de enseñanza superior, organismos de
                  investigación del país o extranjeros, incluido la Unión
                  Europea, la ANR, etc). La ausencia de financiación adjudicada
                  para la ejecución del proyecto es un criterio de no
                  elegibilidad para la convocatoria, salvo para los proyectos
                  del área de Cs Humanas y Sociales: (1.500 caracteres)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  rows="15"
                  maxlength="1500"
                  hide-details="auto"
                  :value="getFormValues('f11_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f11_1')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  NP: El programa ECOS-MinCyT no participa en la selección de
                  los becarios doctorales y posdoctorales del proyecto pero
                  requiere la participación de becarios en los mismos
                </div>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(13)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 13 -->
        <v-stepper-step
          :complete="stepValidationResult[13]"
          ref="step13"
          step="13"
          :rules="[() => stepValidationResult[13]]"
          :class="{ 'active-step': activeStep === 13 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(13)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >11</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >¿Corresponde a una presentación tripartita?
              </span>
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="13" step="13">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep13"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" sm="12" md="8">
                <v-radio-group
                  hide-details
                  row
                  :value="getFormValues('r12_1')"
                  :rules="[validators.required]"
                  @change="(value) => onInputField(value, 'r12_1')"
                >
                  <v-radio
                    label="El proyecto ha sido presentado en su totalidad o una parte a otro organismo"
                    value="El proyecto ha sido presentado en su totalidad o una parte a otro organismo"
                  ></v-radio>
                  <v-radio
                    label="El proyecto no ha sido presentado ante ningún otro organismo, ni siquiera parcialmente."
                    value="El proyecto no ha sido presentado ante ningún otro organismo, ni siquiera parcialmente."
                  ></v-radio>
                  <v-radio
                    label="El proyecto es tripartito con Chile"
                    value="El proyecto es tripartito con Chile"
                  ></v-radio><br>
                  <v-radio
                    label="El proyecto es tripartito con Uruguay"
                    value="El proyecto es tripartito con Uruguay"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                v-if="
                  getFormValues('r12_1') ===
                  'El proyecto ha sido presentado en su totalidad o una parte a otro organismo'
                "
              >
                <v-textarea
                  type="text"
                  label="indicar el presupuesto eventualmente obtenido: (1.500 caracteres)"
                  outlined
                  dense
                  counter
                  rows="15"
                  maxlength="1500"
                  hide-details="auto"
                  :value="getFormValues('f12_1')"
                  @input="(value) => onInputField(value, 'f12_1')"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                v-if="
                  getFormValues('r12_1') ===
                  'El proyecto es tripartito con Uruguay' || getFormValues('r12_1') ===
                  'El proyecto es tripartito con Chile'
                "
              >
                <div class="subtitle-1">
                  <strong
                    >El proyecto debe ser presentado también en la convocatoria
                    de llamados correspondiente (Uruguay y/o Chile)
                  </strong>
                </div>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(14)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 14 -->
        <v-stepper-step
          :complete="stepValidationResult[14]"
          ref="step14"
          step="14"
          :rules="[() => stepValidationResult[14]]"
          :class="{ 'active-step': activeStep === 14 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(14)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >12</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Confirmación</span
              >
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="14" step="14">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep14"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  label="Acepto términos y condiciones de esta convocatoria"
                  :input-value="getFormValues('ch13_1')"
                  @change="(value) => onInputField(value, 'ch13_1')"
                  :rules="[validators.isSelected]"
                ></v-checkbox>
                <v-checkbox
                  label="Confirmo que toda la información completada en este documento es correcta"
                  :input-value="getFormValues('ch13_2')"
                  @change="(value) => onInputField(value, 'ch13_2')"
                  :rules="[validators.isSelected]"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-alert border="left" colored-border text>
                  IMPORTANTE: La candidatura estará oficialmente completada y en
                  estado "Pendiente de evaluación" si la versión en Francés se
                  ha enviado satisfactoriamente.
                </v-alert>
                <v-alert border="left" colored-border text color="error">
                  IMPORTANTE: Para considerar completa la presentación, deberá enviarse la <a href="https://docs.google.com/document/d/18gLQo_1Aw5b_-L2f6Oct8GFDFh7ol02G/edit?usp=sharing&ouid=101368365551661249652&rtpof=true&sd=true" target="_blank"> Planilla Institucional </a> completa y firmada a convocatoriasinternacionales@mincyt.gob.ar hasta el 31/5/2022
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(15)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- End Stepper -->
      </v-stepper>
    </div>
  </div>
</template>

<script>
import {
  ref,
  watch,
  computed,
  getCurrentInstance,
  reactive,
} from "@vue/composition-api";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";

import AnnouncementFileInput from "../AnnouncementFileInput.vue";

import { getVuetify } from "@core/utils";

import ConvocatoriaEs from "./ConvocatoriaEs.vue";

import api from "@/services";
import { getI18nLocale } from "@/plugins/i18n";
import categoriesProject from "@/ddbb/projects/categories";

import {
  required,
  isSelected,
  requiredFile,
  emailValidator,
} from "@core/utils/validation";

export default {
  props: {
    version: {
      default: () => null,
      type: Object,
    },
    step: {
      default: 1,
      type: Number,
    },
    project: {
      default: () => null,
      type: Object,
    },
    isSaveStepsForm: {
      default: false,
      type: Boolean
    },
    readonly: {
      default: false,
      type: Boolean
    },
  },
  components: {
    AnnouncementFileInput,
    ConvocatoriaEs,
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy;
    const localei18n = computed(() => getI18nLocale());
    const filterLocale = ref(localei18n.value);

    const $vuetify = getVuetify();

    const projectLocale = computed(() => project.value[filterLocale.value]);

    const userData = JSON.parse(localStorage.getItem("userData"));
    const { version, step, project: _project, readonly } = props;
    const { t } = useUtilsI18n();
    const activeStep = ref(1);
    const isReadonly = computed(() => readonly);

    // Project
    const getVersion = ref(version);
    const project = ref(_project);
    const _projectStatus = computed(() => project.value?.status);

    const categoriesVersion = reactive([...categoriesProject]);

    const formUpdateProject = reactive({
      name_es: "",
      name_fr: "",
      tags: null,
      categories: [],
    });
    // **** /

    // Forms
    const formData = new FormData();
    const messageSuccess = ref(t("MessageSuccess"));
    // **** /

    const getQuantitySteps = computed(() => 14);

    // Todos los steps esten validos
    const validateAllStepsForm = () => {
      let isValid = true;
      for (let index = 1; index <= getQuantitySteps.value; index++) {
        const r = onValidationFormStep(index);
        stepValidationResult.value[index] = r;
        if (!r) isValid = false;
      }
      return isValid;
    };

    const onValidationFormStep = (step = null) => {
      const _step = step || activeStep.value;
      const validate = vm.$refs[`formStep${_step}`];
      return validate && validate.validate();
    };

    const stepValidationResult = ref({
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: true,
      7: true,
      8: true,
      9: true,
      10: true,
      11: true,
      12: true,
      13: true,
      14: true,
    });

    const isVersionEditForProjectStatus = computed(() => {
      const status = ["draft"];
      return status.includes(_projectStatus.value);
    });

    const isVersionEditForStatus = computed(() => {
      const status = ["draft"];
      return status.includes(getVersion.value.status);
    });

    const isVersionEditForCountry = computed(() => {
      const { country } = userData;
      const { language: versionLang } = getVersion.value;
      return country && country.code.toLowerCase() === versionLang.toLowerCase()
        ? false
        : true;
    });

    const isDisableFormEditmAll = computed(() => {
      // TODO: FIX
      /* return (
        !isVersionEditForProjectStatus.value || isVersionEditForCountry.value || isReadonly.value
      ); */
      return (
        isVersionEditForCountry.value || isReadonly.value
      );
    });

    const getValuesForms = ref(getVersion.value?.values || []);

    const getAttachmentsForms = computed(() => {
      return getVersion.value?.attachments || [];
    });

    const hasFinishStepForm = computed(
      () => activeStep.value === getQuantitySteps.value
    );

    const buttonNextText = computed(() =>
      hasFinishStepForm.value ? t("Send") : t("Next")
    );

    const onInputField = (value, name) => {
      if (getValuesForms.value.find((v) => name === v.name)) {
        getValuesForms.value.find((v) => name === v.name).value = value;
      } else {
        getValuesForms.value = [
          ...getValuesForms.value,
          ...[
            {
              value,
              name: name,
            },
          ],
        ];
      }
    };

    const getFormValues = (name, setDefaultValue = null) => {
      const search = getValuesForms.value.find((v) => name === v.name)?.value;
      if (setDefaultValue && !search) {
        onInputField(setDefaultValue, name);
      }
      return search;
    };

    const onSaveStepsForms = async ({ status = null }) => {
      if (isDisableFormEditmAll.value) return;
      try {
        const {
          project_id: projectId,
          id: versionId,
          language,
          status: statusVersion,
        } = getVersion.value;

        const { data: response } = await api.updateProjectVersionById(
          projectId,
          versionId,
          {
            language,
            status: status || statusVersion,
            values: getValuesForms.value,
          }
        );
        const { data } = response;

        getVersion.value = {
          ...getVersion.value,
          ...data,
        };
        emit("onChangeVersion", { version: getVersion.value });
      } catch (error) {}
    };

    const onStepPrevius = () => {
      const { value } = activeStep;
      if (value > 1) activeStep.value = value - 1;
    };
    const onStepNext = (step = 1) => {
      const _activeStep = activeStep.value;
      const _hasFinishStepForm = hasFinishStepForm.value;
      const _validateAllStepsForm = validateAllStepsForm();

      const isValidateForm = onValidationFormStep();
      stepValidationResult.value[_activeStep] = isValidateForm;

      if (!onValidationFormStep(1) || (!onValidationFormStep(2) && step > 2))
        return;

      setTimeout(() => {
        stepScrolling(step);
      }, 500);

      if (_activeStep !== step && !_hasFinishStepForm) onSaveStepsForms({});
      if (_hasFinishStepForm && _validateAllStepsForm) onFinishStepForm();
      activeStep.value = step;
    };

    const onFinishStepForm = () => {
      onSaveStepsForms({ status: "sent" });
      emit("onFinishForm");
      vm.$alert(messageSuccess.value, null, "success");
    };

    const onUpdateFieldProject = (value, name) => {
      formUpdateProject[name] = value;
      updateProjectById();
    };

    const onUpdateFieldCategoriesProject = () => {
      setTimeout(() => {
        const value = vm.$refs.categoriesProject
        .filter((c) => c.inputValue)
        .map((c) => c.label);

        formUpdateProject.categories = value.toString();
        updateProjectById();
      }, 500)
    };

    const updateProjectById = async () => {
      try {
        const { id: projectId } = project.value;
        await api.updateProjectById(projectId, {
            name_es: formUpdateProject.name_es || project.value?.es.name,
            name_fr: formUpdateProject.name_fr || project.value?.fr.name,
            tags: formUpdateProject.tags || project.value?.tags,
            categories: formUpdateProject.categories || project.value?.categories,
          });
      } catch (error) {}
    };

    const stepScrolling = (step) => {
      const comp = vm.$refs[`step${step}`];
      $vuetify.goTo(comp, {
        duration: 500,
        offset: 80,
        easing: "easeInOutCubic",
      });
    };

    watch(
      () => props.version,
      (version) => {
        getVersion.value = version;
      }
    );

    watch(activeStep, () => emit("onStepActive", { step: activeStep.value }));
    watch(
      () => props.step,
      (s) => (activeStep.value = s)
    );

    watch(
      () => props.isSaveStepsForm,
      (value) => { if(value) onSaveStepsForms({}) }
    );

    return {
      getVersion,
      activeStep,
      getValuesForms,
      getFormValues,
      onInputField,
      isVersionEditForCountry,
      isDisableFormEditmAll,
      hasFinishStepForm,
      buttonNextText,
      stepValidationResult,
      getAttachmentsForms,
      formUpdateProject,
      categoriesVersion,
      projectLocale,

      validators: {
        required,
        isSelected,
        requiredFile,
        emailValidator,
      },

      onStepPrevius,
      onStepNext,
      onSaveStepsForms,
      onUpdateFieldProject,
      onUpdateFieldCategoriesProject,

      t,
    };
  },
};
</script>

<style lang="scss" scope>
.stepper {
  overflow: visible !important;
}
.title-step {
  cursor: pointer;
}
</style>
