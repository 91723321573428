<template>
  <span class="">
    <v-dialog v-model="toggleDialog" persistent max-width="700px">
      <template v-slot:activator="{ on, attrs }">
        <v-chip small :color="color">
          {{ t(getStatus) }}
          <v-icon
            class="pl-2"
            size="30"
            v-bind="attrs"
            v-on="on"
            v-if="isChangeStatus"
            >{{ icons.mdiCircleEditOutline }}</v-icon
          >
        </v-chip>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ t("UpdateProject") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  v-model="selectStatus"
                  :items="getStatusProject"
                  item-value="key"
                  item-text="key"
                >
                  <template v-slot:selection="{ item }">
                    {{ t(item.key) }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ t(item.key) }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onToggleDialog">
            {{ t("Close") }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="changeStatusProjectById"
            :disabled="!selectStatus"
            :loading="toggleLoading"
          >
            {{ t("Send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { ref, computed, reactive } from "@vue/composition-api";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import { mdiCircleEditOutline } from "@mdi/js";
import api from "@/services";
import statusProject from "@/ddbb/projects/status";

export default {
  name: "ProjectStatus",
  props: {
    status: {
      default: null,
    },
    isChangeStatus: {
      default: false,
      type: Boolean,
    },
    project: {
      default: null,
    },
  },
  setup(props, { emit }) {
    const { status, project } = props;
    const { t } = useUtilsI18n();
    const getProject = ref(project);
    const getStatus = ref(status);

    const getStatusProject = reactive([...statusProject]);
    const toggleDialog = ref(false);
    const toggleLoading = ref(false);

    const selectStatus = ref(null);

    const color = computed(() => {
      switch (getStatus.value) {
        case "draft":
          return "";
        case "pending":
          return "warning";
        case "reviewed":
          return "info";
        case "approved":
          return "success";
        case "rejected":
          return "error";

        default:
          return null;
      }
    });

    const onToggleDialog = () => {
      toggleDialog.value = !toggleDialog.value;
    };

    const onToggleLoading = () => {
      toggleLoading.value = !toggleLoading.value;
    };

    const changeStatusProjectById = async () => {
      onToggleLoading();

      try {
        const { id: projectId } = getProject.value;
        const { data: response } = await api.changeStatusProjectById(
          projectId,
          {
            status: selectStatus.value,
          }
        );

        const { data } = response;

        getStatus.value = data.status;
        emit("onChangeProject", data);

        onToggleDialog();
      } catch (error) {}

      onToggleLoading();
    };

    return {
      toggleDialog,
      color,
      getStatusProject,
      selectStatus,
      getStatus,
      toggleLoading,

      icons: {
        mdiCircleEditOutline,
      },

      changeStatusProjectById,
      onToggleDialog,
      t,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-chip {
  cursor: pointer;
}
</style>
