<template>
  <div class="wrapper__main-form py-10 px-10" v-if="getVersion">
    <div class="form-steps">
      <v-row>
        <v-col cols="12" sm="12" md="12" v-if="isDisableFormEditmAll">
          <v-alert border="left" colored-border text color="error">
            IMPORTANT! Le projet est en mode lecture. L'appel à candidatures s'est clôturé le 15/04/2023.
          </v-alert>
        </v-col>
      </v-row>
      <v-stepper v-model="activeStep" vertical class="custom-header">
        <!-- Step 1 -->
        <v-stepper-step
          :complete="stepValidationResult[1]"
          step="1"
          ref="step1"
          :rules="[() => stepValidationResult[1]]"
          :class="{ 'active-step': activeStep === 2 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(1)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
            ></span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Eléments nécessaires à la présentation (en ligne) permettant de valider l'éligibilité par L’administration
              </span>
              <span class="text--secondary text-xs"
                >Vérifiez que l'équipe argentine remplisse les conditions nécessaires pour le côté argentin. Pour l’équipe française</span
              >
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="1" step="1">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep1"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12">
                <div class="subtitle-1">
                  <strong>
                    1. Présence d'au moins 2 chercheurs titulaires dans l'équipe
                    française
                  </strong>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  type="text"
                  label="Indiquez le nom des chercheurs"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f1_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f1_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="subtitle-1">
                  <strong>
                    2. Au moins un des chercheurs français (coordonnateur ou
                    associé) est habilité à diriger des recherches (Habilitation
                    à Diriger des Recherches -HDR)
                  </strong>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  type="text"
                  label="Indiquez le nom du chercheur avec HDR"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f1_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f1_2')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="subtitle-1">
                  <strong>
                    3. Doctorant(s) associé(s) au projet (l'une des 3 conditions
                    listées ci-dessous doit être remplie).
                  </strong>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-radio-group
                  hide-details
                  row
                  :value="getFormValues('r1_1')"
                  :rules="[validators.required]"
                  @change="(value) => onInputField(value, 'r1_1')"
                >
                  <v-radio
                    label="a)	un doctorant est identifié par le laboratoire au moment du dépôt, le nommer"
                    value="r1_1_a"
                  ></v-radio>
                  <v-radio
                    label="b)	le doctorant n'est pas identifié par le laboratoire au moment du dépôt, mais le financement est disponible pour couvrir la bourse   "
                    value="r1_1_b"
                  ></v-radio>
                  <v-radio
                    label="c)	le doctorant n'est pas identifié par le laboratoire au moment du dépôt, mais un financement est prévu pour couvrir la bourse  "
                    value="r1_1_c"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-if="getFormValues('r1_1') === 'r1_1_a'"
                  type="text"
                  label="Indiquer le(s) nom(s) de l’étudiant ou des étudiants en doctorat"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f1_3_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f1_3_1')"
                ></v-text-field>
                <v-text-field
                  v-if="getFormValues('r1_1') === 'r1_1_b'"
                  type="text"
                  label="Indiquer le financement disponible"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f1_3_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f1_3_2')"
                ></v-text-field>
                <v-text-field
                  v-if="getFormValues('r1_1') === 'r1_1_c'"
                  type="text"
                  label="Indiquer le montant envisagé"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f1_3_3')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f1_3_3')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="getFormValues('r1_1') === 'r1_1_c'">
                <div class="subtitle-1">
                  Remarque : Si l'étudiant n'est pas recruté ou si le
                  financement prévu n'est pas disponible avant le début du
                  projet (1er janvier 2023), le financement ECOS est perdu. Ces
                  informations doivent être communiquées avant le 1er novembre
                  2022.
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                v-if="getFormValues('r1_1') === 'r1_1_a'"
              >
                <AnnouncementFileInput
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :name-key="'a1_1'"
                  label="Joindre son/ses CV .pdf"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                v-if="getFormValues('r1_1') === 'r1_1_a'"
              >
                <AnnouncementFileInput
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :name-key="'a1_2'"
                  label="Joindre son/ses CV .pdf"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="subtitle-1">
                  <strong>
                    4. Financement propre du projet (sauf exception des projets
                    dans le domaine des Sciences Humaines et Sociales – SHS)
                  </strong>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Indiquer le type de subvention "
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f1_4_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f1_4_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Indiquer le montant financé : Montant euros/3 ans"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f1_4_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f1_4_2')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="subtitle-1">
                  (Précision : la candidature sera considérée comme inéligible
                  si le financement n'est pas suffisant pour mener à bien
                  l'expérimentation projetée en France).
                </div>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              color="primary"
              @click="onStepNext(2)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 2 -->
        <v-stepper-step
          :complete="stepValidationResult[2]"
          ref="step2"
          step="2"
          :rules="[() => stepValidationResult[2]]"
          :class="{ 'active-step': activeStep === 2 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(2)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
            ></span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Présentation du programme et guide de bonnes pratiques</span
              >
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="2" step="2">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep2"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12">
                <ConvocatoriaFr />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  label="O J´ai lu et j´accepte toutes les conditions"
                  :input-value="getFormValues('ch1_1')"
                  @change="(value) => onInputField(value, 'ch1_1')"
                  :rules="[validators.isSelected]"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(3)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 3 -->
        <v-stepper-step
          :complete="stepValidationResult[3]"
          ref="step3"
          step="3"
          :rules="[() => stepValidationResult[3]]"
          :class="{ 'active-step': activeStep === 3 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(3)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >01</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Titre du projet
              </span>
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="3" step="3">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep3"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12">
                <div class="subtitle-1">Titre du projet:</div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f2_1', projectLocale.name)"
                  :rules="[validators.required]"
                  @change="(value) => onUpdateFieldProject(value, 'name_fr')"
                  @input="(value) => onInputField(value, 'f2_1')"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <div class="subtitle-1">Mots-clés (4 maximum):</div>
                <v-text-field
                  type="text"
                  label=""
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f2_2')"
                  :rules="[validators.required]"
                  @change="(value) => onUpdateFieldProject(value, 'tags')"
                  @input="(value) => onInputField(value, 'f2_2')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3>
                  Champ disciplinaire (cocher) :
                </h3>
                <v-radio-group
                  :value="getFormValues('f2_3')"
                  :rules="[validators.required]"
                >
                  <v-radio
                    v-for="(category, index) in categoriesVersion"
                    :key="index"
                    :label="t(category.key)"
                    ref="categoriesProject"
                    :value="category.value"
                    @change="
                      () => {
                        onInputField(category.value, 'f2_3');
                        onUpdateFieldCategoriesProject(category.key);
                      }
                    "
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3>
                  Champ disciplinaire secondaire  (cocher) :
                </h3>
                <v-radio-group :value="getFormValues('f2_4')">
                  <v-radio
                      v-for="(category, index) in categoriesVersion"
                      :key="index"
                      :label="t(category.key)"
                      :value="category.value"
                      @change="
                        () => {
                          onInputField(category.value, 'f2_4');
                        }
                      "
                    ></v-radio>
                  </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(4)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 4 -->
        <v-stepper-step
          :complete="stepValidationResult[4]"
          ref="step4"
          step="4"
          :rules="[() => stepValidationResult[4]]"
          :class="{ 'active-step': activeStep === 4 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(4)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >02</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Etablissement principal</span
              >
              <span class="text--secondary text-xs"
                >Auquel appartient le responsable scientifique du projet
              </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="4" step="4">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep4"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" class="mt-5"> <h3>En France:</h3></v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="*Laboratoire/CENTRE (ou équipe) "
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f3_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f3_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nom du Directeur/de la Directrice "
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f3_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f3_2')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-5"
                ><div class="subtitle-1">
                  *Indiquer le statut de l’Unité de recherche : UPRES, EA, UMR,
                  UPR, U. INSERM, U. INRA, etc.
                </div></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5"> <h3>En Argentine:</h3></v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Laboratoire/CENTRE  (ou équipe)  "
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f3_3')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f3_3')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nom du Directeur"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f3_4')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f3_4')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Pour que la candidature soit considérée complète, le formulaire d’aval institutionnel complété et signé doit être téléchargé sur la plateforme avant le 30/04/2023 : voir étape 13.
                </div>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(5)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 5 -->
        <v-stepper-step
          :complete="stepValidationResult[5]"
          ref="step5"
          step="5"
          :rules="[() => stepValidationResult[5]]"
          :class="{ 'active-step': activeStep === 5 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(5)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >03</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Responsables du projet
              </span>
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="5" step="5">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep5"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" class="mt-5"> <h3>*En France:</h3></v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nom et prénom"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_1_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f4_1_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Grade"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_1_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f4_1_2')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Adresse administrative"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_1_3')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f4_1_3')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <AnnouncementFileInput
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :name-key="'a4_1'"
                  label=" Joignez le CV du/de la Directeur/Directrice de projet pour la France en annexe pdf 2MB"
                />
                <div class="subtitle-1">
                  <a href="https://docs.google.com/document/d/1_ZPyE57i0vZP2yqGXWBU7IqgTgXsqJ25/edit?usp=share_link&ouid=102984505465769706787&rtpof=true&sd=true" target="_blank"> Téléchargez le modèle *ici*  </a>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Téléphone portable"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_1_4')"
                  @input="(value) => onInputField(value, 'f4_1_4')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Téléphone fixe"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_1_4_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f4_1_4_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Courrier electronique"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_1_5')"
                  :rules="[validators.required, validators.emailValidator]"
                  @input="(value) => onInputField(value, 'f4_1_5')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-5"
                ><div class="subtitle-1">
                  *Le chercheur français responsable du projet doit être
                  habilité à diriger des recherches. Un jeune chercheur non
                  diplômé titulaire d'une HDR peut cependant co-diriger le
                  projet, à condition qu'au moins un membre de son équipe,
                  titulaire de l'HDR, soit impliqué dans la formation des
                  doctorants
                </div></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5"> <h3>En Argentina:</h3></v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nom et prénom"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_2_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f4_2_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Grade"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_2_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f4_2_2')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Adresse administrative"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_2_3')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f4_2_3')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <AnnouncementFileInput
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :name-key="'a4_2'"
                  label="Joignez le CV du/de la Directeur/Directrice de projet pour la Argentine en annexe pdf 2MB"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Téléphone portable"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_2_4')"
                  @input="(value) => onInputField(value, 'f4_2_4')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Téléphone fixe"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_2_4_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f4_2_4_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Courrier electronique"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f4_2_5')"
                  :rules="[validators.required, validators.emailValidator]"
                  @input="(value) => onInputField(value, 'f4_2_5')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(6)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 6 -->
        <v-stepper-step
          :complete="stepValidationResult[6]"
          ref="step6"
          step="6"
          :rules="[() => stepValidationResult[6]]"
          :class="{ 'active-step': activeStep === 6 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(6)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >04</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Liste des chercheurs participants
              </span>
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="6" step="6">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep6"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" class="mt-5">
                <div class="subtitle-1">
                  Liste des chercheurs participants (catégorie et institution à laquelle ils appartiennent) et boursiers (catégorie et institution à laquelle ils appartiennent) participant au projet (si nécessaire, identifier les chercheurs responsables, les bénéficiaires des mobilités et les chercheurs associés ou occasionnels. A noter que 2 chercheurs dans chaque équipe est le nombre minimum de membres, mais selon le nombre de doctorants ou post-doctorants, un nombre allant jusqu'à 10 chercheurs principaux peut être raisonnable pour chaque équipe de travail.
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="mt-5">
                <div class="subtitle-1">
                  Est-ce qu'un membre de l'équipe a déjà participé à un projet ECOS?
                </div>
                <v-radio-group
                  hide-details
                  row
                  :value="getFormValues('f5_1')"
                  :rules="[validators.required]"
                  @change="(value) => onInputField(value, 'f5_1')"
                >
                  <v-radio
                    label="Oui"
                    value="Oui"
                  ></v-radio>
                  <v-radio
                    label="Non"
                    value="Non"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5">
                <div class="subtitle-1">
                  Si oui, veuillez indiquer le(s) nom(s) et l'année de l'appel et/ou le code du projet.
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f5_1_1')"
                  @input="(value) => onInputField(value, 'f5_1_1')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5">
                <div class="subtitle-1">
                  <p>ÉTAPE 1 - Téléchargez le modèle de CV en cliquant <a href="https://docs.google.com/document/d/1_ZPyE57i0vZP2yqGXWBU7IqgTgXsqJ25/edit?usp=share_link&ouid=102984505465769706787&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer">ici</a></p>
                  <p>ÉTAPE 2 - Dupliquez le fichier, un pour chaque membre de l'équipe.</p>
                  <p>ÉTAPE 3 - Complétez les modèles avec les informations de chaque membre de l'équipe.</p>
                  <p>ETAPE 4 - Exportez le fichier au format PDF</p>
                  <p>ÉTAPE 5 - Téléchargez les CV en utilisant une ligne pour chaque membre de l'équipe. Vous pouvez télécharger jusqu'à 10 (dix) membres.</p>
                  <p>ÉTAPE 6 - Cochez le bouton de vérification (check) pour confirmer que tous les membres de l'équipe ont été renseignés.</p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5"> <h3>Responsable français du projet</h3></v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nom et prénom"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f5_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f5_2')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <AnnouncementFileInput
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :name-key="'a5_2'"
                  label="Joignez le CV en annexe pdf 2MB"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5"> <h3>Membres chercheurs de l'équipe française</h3></v-col>
            </v-row>
            <v-row v-for="index in 10" :key="index">
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nom et prénom"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues(`f5_3_${index}`)"
                  @input="(value) => onInputField(value, `f5_3_${index}`)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <AnnouncementFileInput
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :required="false"
                  :name-key="`a5_3_${index}`"
                  label="Joignez le CV en annexe pdf 2MB"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5">
                <div class="subtitle-1">
                  Si des informations supplémentaires sont nécessaires, veuillez joindre un fichier :
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <AnnouncementFileInput
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :required="false"
                  :name-key="'a5_4'"
                  label="Adjuntar Anexo pdf Menor a 2MB"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  label="Je confirme que nous avons complété la liste des membres de l'équipe française."
                  :input-value="getFormValues('ch5_1')"
                  @change="(value) => onInputField(value, 'ch5_1')"
                  :rules="[validators.isSelected]"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  label="Je confirme qu'aucun membre de l'équipe n'est impliqué dans un projet ECOS en cours"
                  :input-value="getFormValues('ch5_2')"
                  @change="(value) => onInputField(value, 'ch5_2')"
                  :rules="[validators.isSelected]"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(7)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 7 -->
        <v-stepper-step
          :complete="stepValidationResult[7]"
          ref="step7"
          step="7"
          :rules="[() => stepValidationResult[7]]"
          :class="{ 'active-step': activeStep === 7 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(7)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >05</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Résumé scientifique
              </span>
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="7" step="7">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep7"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" class="mt-5">
                <div class="subtitle-1">
                  Résumé scientifique non confidentiel pour diffusion du projet (français et espagnol - anglais optionnel). Si le projet est retenu, ces résumés seront publiés sur cette plateforme
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  type="text"
                  label="Francés"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f6_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f6_1')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  type="text"
                  label="Castellano"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f6_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f6_2')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  type="text"
                  label="Inglés"
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f6_3')"
                  @input="(value) => onInputField(value, 'f6_3')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(8)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 8 -->
        <v-stepper-step
          :complete="stepValidationResult[8]"
          ref="step8"
          step="8"
          :rules="[() => stepValidationResult[8]]"
          :class="{ 'active-step': activeStep === 8 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(8)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >06</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Description du projet scientifique
              </span>
              <span class="text--secondary text-xs">Total : 24 000 caractères maximum espaces compris </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="8" step="8">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep8"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" class="mt-5">
                <h3>Description du projet scientifique.</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Problématique et contexte bibliographique (9 000 caractères max.)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  rows="20"
                  maxlength="9000"
                  hide-details="auto"
                  :value="getFormValues('f7_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f7_1')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Objectifs et hypothèses (3 000 caractères max.)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  maxlength="3000"
                  hide-details="auto"
                  :value="getFormValues('f7_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f7_2')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Méthodologie et plan de travail (6 000 caractères max.)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  rows="15"
                  maxlength="6000"
                  hide-details="auto"
                  :value="getFormValues('f7_3')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f7_3')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Participation de chaque équipe et valeur ajoutée dans la collaboration (pour les deux équipes et pour le projet) (3 000 caractères max.)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  maxlength="3000"
                  hide-details="auto"
                  :value="getFormValues('f7_4')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f7_4')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Résultats attendus (3 000 caractères max)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  maxlength="3000"
                  hide-details="auto"
                  :value="getFormValues('f7_5')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f7_5')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5">
                <div class="subtitle-1">
                  Les documents scientifiques supplémentaires à télécharger seront limités à des fichiers PDF de 2 pages pour les figures et les tableaux, etc. <br />
                  Le texte (selon la limite de caractères) devra être déposé en ligne exclusivement.
                </div>
                
                <AnnouncementFileInput
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :name-key="'a7_1'"
                  label="Joindre en annexe pdf 2MB"
                />
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(9)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 9 -->
        <v-stepper-step
          :complete="stepValidationResult[9]"
          ref="step9"
          step="9"
          :rules="[() => stepValidationResult[9]]"
          :class="{ 'active-step': activeStep === 9 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(9)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >07</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Plan de formation des doctorants
              </span>
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="9" step="9">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep9"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Iinclure une brève description des objectifs généraux et spécifiques du plan de travail et de formation pour chaque doctorant, 3000 signes
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  rows="15"
                  maxlength="3000"
                  hide-details="auto"
                  :value="getFormValues('f8_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f8_1')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(10)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 10 -->
        <v-stepper-step
          :complete="stepValidationResult[10]"
          ref="step10"
          step="10"
          :rules="[() => stepValidationResult[10]]"
          :class="{ 'active-step': activeStep === 10 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(10)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >08</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Mobilités pour la première année
              </span>
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="10" step="10">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep10"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" class="mt-5">
                <div class="subtitle-1">
                  Moyens demandés pour effectuer des missions de recherche et/ou
                  de formation des doctorants pour la première année d'exécution
                  du projet
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5">
                <h3>DEPUIS L’ARGENTINE VERS LA FRANCE</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Duración: Options (14 jours pour les missions senior/ 30 jours pour les stages junior)
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  :items="['mission senior ', 'stage junior']"
                  label="Type de mobilité "
                  outlined
                  :value="getFormValues('s9_1_1')"
                  :rules="[validators.required]"
                  @change="(value) => onInputField(value, 's9_1_1')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nom et prénom du bénéficiaire : "
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f9_1_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_1_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Justification détaillée de la nécessité de la mission par rapport au projet et plan de travail (Maximum 800 caractères)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  maxlength="800"
                  hide-details="auto"
                  :value="getFormValues('f9_1_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_1_2')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Duración: Options (14 jours pour les missions senior/ 30 jours pour les stages junior)
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  :items="['mission senior ', 'stage junior']"
                  label="Type de mobilité"
                  outlined
                  :value="getFormValues('s9_2_1')"
                  :rules="[validators.required]"
                  @change="(value) => onInputField(value, 's9_2_1')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nom et prénom du bénéficiaire : "
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f9_2_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_2_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Justification détaillée de la nécessité de la mission par rapport au projet et plan de travail (Maximum 800 caractères)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  maxlength="800"
                  hide-details="auto"
                  :value="getFormValues('f9_2_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_2_2')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-5">
                <h3>DEPUIS LA FRANCE VERS L’ARGENTINE</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Durée : Options (14 jours pour les missions senior/ 30 jours pour les stages junior)
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  :items="['mission senior ', 'stage junior']"
                  label="Type de mobilité"
                  outlined
                  :value="getFormValues('s9_3_1')"
                  :rules="[validators.required]"
                  @change="(value) => onInputField(value, 's9_3_1')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nom et prénom du bénéficiaire : "
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f9_3_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_3_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Justification détaillée de la nécessité de la mission par rapport au projet et plan de travail (Maximum 800 caractères espaces compris) 
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  maxlength="800"
                  hide-details="auto"
                  :value="getFormValues('f9_3_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_3_2')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Durée : Options (14 jours pour les missions senior/ 30 jours pour les stages junior)
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  :items="['mission senior ', 'stage junior']"
                  label="Type de mobilité"
                  outlined
                  :value="getFormValues('s9_4_1')"
                  :rules="[validators.required]"
                  @change="(value) => onInputField(value, 's9_4_1')"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  type="text"
                  label="Nom et prénom du bénéficiaire : "
                  outlined
                  dense
                  hide-details="auto"
                  :value="getFormValues('f9_4_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_4_1')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  Justification détaillée de la nécessité de la mission par
                  rapport au projet et plan de travail (Maximum 800 caractères)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  maxlength="800"
                  hide-details="auto"
                  :value="getFormValues('f9_4_2')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f9_4_2')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(11)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 11 -->
        <v-stepper-step
          :complete="stepValidationResult[11]"
          ref="step11"
          step="11"
          :rules="[() => stepValidationResult[11]]"
          :class="{ 'active-step': activeStep === 11 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(11)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >09</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Historique de la coopération avec la partie argentine
              </span>
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="11" step="11">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep11"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  (Joindre en annexe, le cas échéant, les références des co-publications, thèses, co-projets, brevets, etc.). Cette rubrique doit obligatoirement mentionner les projets antérieurs à ECOS pertinents pour cette coopération (3.000 caractères).
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  rows="15"
                  maxlength="3000"
                  hide-details="auto"
                  :value="getFormValues('f10_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f10_1')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <AnnouncementFileInput
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :name-key="'a10_1'"
                  label="Joindre en annexe pdf 2MB"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <AnnouncementFileInput
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :name-key="'a10_2'"
                  label="Joindre en annexe pdf 2MB"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <AnnouncementFileInput
                  :required="false"
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :name-key="'a10_3'"
                  label="Joindre en annexe pdf 2MB"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <AnnouncementFileInput
                  :required="false"
                  :disabled="isDisableFormEditmAll"
                  :attachments="getAttachmentsForms"
                  :project-version-id="getVersion.id"
                  :name-key="'a10_4'"
                  label="Joindre en annexe pdf 2MB"
                />
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(12)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 12 -->
        <v-stepper-step
          :complete="stepValidationResult[12]"
          ref="step12"
          step="12"
          :rules="[() => stepValidationResult[12]]"
          :class="{ 'active-step': activeStep === 12 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(12)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >10</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Moyens
              </span>
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="12" step="12">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep12"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  <strong>
                    Financement pertinent pour le projet provenant d'autres sources de financement  </strong
                  >(Il est OBLIGATOIRE de détailler les financements provenant
                  de différentes sources dont le secteur industriel, les
                  établissements d'enseignement supérieur, les organismes de
                  recherche nationaux ou étrangers, dont l'Union européenne,
                  l'ANR, etc.). L'absence de financement accordé pour la
                  réalisation du projet est un critère de non-éligibilité à
                  l'appel, sauf pour les projets du domaine SHS : (1500
                  caractères)
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  rows="15"
                  maxlength="1500"
                  hide-details="auto"
                  :value="getFormValues('f11_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f11_1')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  NP : Le programme ECOS-MinCyT ne participe pas à la sélection des doctorants et postdoctorants pour le projet
                </div>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(13)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 13 -->
        <v-stepper-step
          :complete="stepValidationResult[13]"
          ref="step13"
          step="13"
          :rules="[() => stepValidationResult[13]]"
          :class="{ 'active-step': activeStep === 13 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(13)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >11</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Information complémentaire 
              </span>
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="13" step="13">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep13"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" sm="12" md="8">
                <v-radio-group
                  hide-details
                  row
                  :value="getFormValues('r12_1')"
                  :rules="[validators.required]"
                  @change="(value) => onInputField(value, 'r12_1')"
                >
                  <v-radio
                    label="Ce projet a été présenté en totalité ou en partie à un autre organisme"
                    value="Ce projet a été présenté en totalité ou en partie à un autre organisme"
                  ></v-radio>
                  <v-radio
                    label="Ce projet n'a été soumis à aucun organisme, même partiellement."
                    value="Ce projet n'a été soumis à aucun organisme, même partiellement."
                  ></v-radio>
                  <v-radio
                    label="Ce projet est tripartite avec le Chili"
                    value="Chili"
                  ></v-radio>
                  <v-radio
                    label='Ce projet est tripartite avec l´Uruguay'
                    value='Uruguay'
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                v-if="
                  getFormValues('r12_1') ===
                  'Ce projet a été présenté en totalité ou en partie à un autre organisme'
                "
              >
                <v-textarea
                  type="text"
                  label="indiquer les moyens éventuellement obtenus (1.500 caractères):"
                  outlined
                  dense
                  counter
                  rows="15"
                  maxlength="1500"
                  hide-details="auto"
                  :value="getFormValues('f12_1')"
                  @input="(value) => onInputField(value, 'f12_1')"
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                v-if="
                  getFormValues('r12_1') ===
                  'Uruguay' ||  getFormValues('r12_1') ===
                  'Chili'
                "
              >
                <div class="subtitle-1">
                  <strong
                    >Le projet doit être déposé également dans le cadre de
                    l’appel à projets correspondant (Uruguay et/ou Chili.)
                  </strong>
                </div>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(14)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 14 -->
        <v-stepper-step
          :complete="stepValidationResult[14]"
          ref="step14"
          step="14"
          :rules="[() => stepValidationResult[14]]"
          :class="{ 'active-step': activeStep === 14 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(14)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >12</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Liste des experts recommandés</span
              >
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="14" step="14">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep14"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="subtitle-1">
                  <strong
                    >Liste des experts recommandés (sans confits d’interêts o pour l’évaluation française avec leurs coordonnées
                  </strong>
                </div>
                <v-textarea
                  type="text"
                  label=""
                  outlined
                  dense
                  counter
                  rows="15"
                  maxlength="1500"
                  hide-details="auto"
                  :value="getFormValues('f13_1')"
                  :rules="[validators.required]"
                  @input="(value) => onInputField(value, 'f13_1')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(15)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- Step 15 -->
        <v-stepper-step
          :complete="stepValidationResult[15]"
          ref="step15"
          step="15"
          :rules="[() => stepValidationResult[15]]"
          :class="{ 'active-step': activeStep === 15 }"
        >
          <div class="d-flex align-center title-step" @click="onStepNext(15)">
            <span
              class="text--primary text-4xl font-weight-bold me-3 step-number"
              >13</span
            >
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Confirmation</span
              >
              <span class="text--secondary text-xs"> </span>
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content key="15" step="15">
          <v-form
            :readonly="isDisableFormEditmAll"
            ref="formStep15"
            class="pb-6 pt-2 mt-10"
          >
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                  label="J’accepte les termes et conditions de cet Appel à projets"
                  :input-value="getFormValues('ch14_1')"
                  @change="(value) => onInputField(value, 'ch14_1')"
                  :rules="[validators.isSelected]"
                ></v-checkbox>
                <v-checkbox
                  label="Je confirme que toute l’information renseignée dans ce document est correcte"
                  :input-value="getFormValues('ch14_2')"
                  @change="(value) => onInputField(value, 'ch14_2')"
                  :rules="[validators.isSelected]"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-alert border="left" colored-border text>
                  IMPORTANT: La candidature sera officiellement complète et en statut “En attente d’évaluation” si la version espagnole est envoyée avec succès.
                </v-alert>
                <v-alert border="left" colored-border text color="error">
                  IMPORTANT : Pour que la demande soit considérée complète, le formulaire  <a :href="LINK_2023_FR_DOWNLOAD_AVAL_INSTITUTIONAL" target="_blank">d’aval institutionnel</a> rempli et signé doit être téléchargé avant le 30/04/2023
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
          <div class="py-10">
            <v-btn
              outlined
              @click="onStepPrevius"
              :disabled="isDisableFormEditmAll"
              class="mr-4"
            >
              {{ t("Back") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="onStepNext(16)"
              :disabled="isDisableFormEditmAll"
            >
              {{ buttonNextText }}
            </v-btn>
          </div>
        </v-stepper-content>

        <!-- End Stepper -->
      </v-stepper>
    </div>
  </div>
</template>

<script>
import {
  ref,
  watch,
  computed,
  getCurrentInstance,
  reactive,
} from "@vue/composition-api";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";

import AnnouncementFileInput from "../AnnouncementFileInput.vue";

import { getVuetify } from "@core/utils";

import ConvocatoriaFr from "./ConvocatoriaFr.vue";

import api from "@/services";
import { getI18nLocale } from "@/plugins/i18n";
import categoriesProject from "@/ddbb/projects/categories";
import { LINK_2023_FR_DOWNLOAD_AVAL_INSTITUTIONAL } from "@/assets/util";

import {
  required,
  isSelected,
  requiredFile,
  emailValidator,
} from "@core/utils/validation";

export default {
  props: {
    version: {
      default: () => null,
      type: Object,
    },
    step: {
      default: 1,
      type: Number,
    },
    project: {
      default: () => null,
      type: Object,
    },
    isSaveStepsForm: {
      default: false,
      type: Boolean
    },
    readonly: {
      default: true,
      type: Boolean
    },
    canEditAdmin: {
      default: false,
      type: Boolean
    }
  },
  components: {
    AnnouncementFileInput,
    ConvocatoriaFr,
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy;
    const localei18n = computed(() => getI18nLocale());
    const filterLocale = ref(localei18n.value);

    const $vuetify = getVuetify();

    const projectLocale = computed(() => project.value[filterLocale.value]);

    const userData = JSON.parse(localStorage.getItem("userData"));
    const { version, step, project: _project, readonly, canEditAdmin } = props;
    const { t } = useUtilsI18n();
    const activeStep = ref(1);
    const isReadonly = computed(() => readonly);

    // Project
    const getVersion = ref(version);
    const project = ref(_project);
    const _projectStatus = computed(() => project.value?.status);

    const categoriesVersion = reactive([...categoriesProject]);

    const formUpdateProject = reactive({
      name_es: null,
      name_fr: null,
      tags: null,
      categories: [],
    });
    // **** /

    // Forms
    const formData = new FormData();
    const messageSuccess = ref(t("MessageSuccess"));
    // **** /

    const getQuantitySteps = computed(() => 15);

    // Todos los steps esten validos
    const validateAllStepsForm = () => {
      let isValid = true;
      for (let index = 1; index <= getQuantitySteps.value; index++) {
        const r = onValidationFormStep(index);
        stepValidationResult.value[index] = r;
        if (!r) isValid = false;
      }
      return isValid;
    };

    const onValidationFormStep = (step = null) => {
      const _step = step || activeStep.value;
      const validate = vm.$refs[`formStep${_step}`];
      return validate && validate.validate();
    };

    const stepValidationResult = ref({
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
      6: true,
      7: true,
      8: true,
      9: true,
      10: true,
      11: true,
      12: true,
      13: true,
      14: true,
      15: true,
    });

    const isVersionEditForProjectStatus = computed(() => {
      const status = ["draft"];
      return status.includes(_projectStatus.value);
    });

    const isVersionEditForStatus = computed(() => {
      const status = ["draft"];
      return status.includes(getVersion.value.status);
    });

    const isVersionEditForCountry = computed(() => {
      const { country } = userData;
      const { language: versionLang } = getVersion.value;
      return country && country.code.toLowerCase() === versionLang.toLowerCase()
        ? false
        : true;
    });

    const isDisableFormEditmAll = computed(() => {
      // TODO: FIX
      /* return (
        !isVersionEditForProjectStatus.value || isVersionEditForCountry.value || isReadonly.value
      ); */
      if(canEditAdmin) return false;
      return (
        isVersionEditForCountry.value || isReadonly.value
      );
    });

    const getValuesForms = ref(getVersion.value?.values || []);

    const getAttachmentsForms = computed(() => {
      return getVersion.value?.attachments || [];
    });

    const hasFinishStepForm = computed(
      () => activeStep.value === getQuantitySteps.value
    );

    const buttonNextText = computed(() =>
      hasFinishStepForm.value ? t("Send") : t("Next")
    );

    const onInputField = (value, name) => {
      if (getValuesForms.value.find((v) => name === v.name)) {
        getValuesForms.value.find((v) => name === v.name).value = value;
      } else {
        getValuesForms.value = [
          ...getValuesForms.value,
          ...[
            {
              value,
              name: name,
            },
          ],
        ];
      }
    };

    const getFormValues = (name, setDefaultValue = null) => {
      const search = getValuesForms.value.find((v) => name === v.name)?.value;
      if (setDefaultValue && !search) {
        onInputField(setDefaultValue, name);
      }
      return search;
    };

    const onSaveStepsForms = async ({ status = null }) => {
      if (isDisableFormEditmAll.value) return;
      try {
        const {
          project_id: projectId,
          id: versionId,
          language,
          status: statusVersion,
        } = getVersion.value;

        const { data: response } = await api.updateProjectVersionById(
          projectId,
          versionId,
          {
            language,
            status: status || statusVersion,
            values: getValuesForms.value,
          }
        );
        const { data } = response;

        getVersion.value = {
          ...getVersion.value,
          ...data,
        };
        emit("onChangeVersion", { version: getVersion.value });
      } catch (error) {}
    };

    const onStepPrevius = () => {
      const { value } = activeStep;
      if (value > 1) activeStep.value = value - 1;
    };
    const onStepNext = (step = 1) => {
      const _activeStep = activeStep.value;
      const _hasFinishStepForm = hasFinishStepForm.value;
      const _validateAllStepsForm = validateAllStepsForm();

      const isValidateForm = onValidationFormStep();
      stepValidationResult.value[_activeStep] = isValidateForm;

      if (!onValidationFormStep(1) || (!onValidationFormStep(2) && step > 2))
        return;

      setTimeout(() => {
        stepScrolling(step);
      }, 500);

      if (_activeStep !== step && !_hasFinishStepForm) onSaveStepsForms({});
      if (_hasFinishStepForm && _validateAllStepsForm) onFinishStepForm();
      if (_hasFinishStepForm && !_validateAllStepsForm) vm.$alert(vm.$t("MESSAGE_IMCOMPLETE_SAVE_PROYECT"), null, "warning");
      activeStep.value = step;
    };

    const onFinishStepForm = () => {
      onSaveStepsForms({ status: "sent" });
      emit("onFinishForm");
      vm.$alert(messageSuccess.value, null, "success");
    };

    const onUpdateFieldProject = (value, name) => {
      formUpdateProject[name] = value;
      updateProjectById();
    };

    const onUpdateFieldCategoriesProject = (value) => {
      setTimeout(() => {
        formUpdateProject.categories = [value].toString();
        updateProjectById();
      }, 500)
    };

    const updateProjectById = async () => {
      try {
        const { id: projectId } = project.value;
        await api.updateProjectById(projectId, {
            name_es: formUpdateProject.name_es || project.value?.es.name,
            name_fr: formUpdateProject.name_fr || project.value?.fr.name,
            tags: formUpdateProject.tags || project.value?.tags,
            categories: formUpdateProject.categories || project.value?.categories,
          });
      } catch (error) {}
    };

    const stepScrolling = (step) => {
      const comp = vm.$refs[`step${step}`];
      $vuetify.goTo(comp, {
        duration: 500,
        offset: 80,
        easing: "easeInOutCubic",
      });
    };

    watch(
      () => props.version,
      (version) => {
        getVersion.value = version;
      }
    );

    watch(activeStep, () => emit("onStepActive", { step: activeStep.value }));
    watch(
      () => props.step,
      (s) => (activeStep.value = s)
    );

    watch(
      () => props.isSaveStepsForm,
      (value) => { if(value) onSaveStepsForms({}) }
    );

    return {
      LINK_2023_FR_DOWNLOAD_AVAL_INSTITUTIONAL,
      getVersion,
      activeStep,
      getValuesForms,
      getFormValues,
      onInputField,
      isVersionEditForCountry,
      isDisableFormEditmAll,
      hasFinishStepForm,
      buttonNextText,
      stepValidationResult,
      getAttachmentsForms,
      formUpdateProject,
      categoriesVersion,
      projectLocale,

      validators: {
        required,
        isSelected,
        requiredFile,
        emailValidator,
      },

      onStepPrevius,
      onStepNext,
      onSaveStepsForms,
      onUpdateFieldProject,
      onUpdateFieldCategoriesProject,

      t,
    };
  },
};
</script>

<style lang="scss" scope>
.stepper {
  overflow: visible !important;
}
.title-step {
  cursor: pointer;
}
</style>
