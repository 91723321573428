<template>
  <v-img
    :src="require(`@/assets/images/flags/${locale}.png`)"
    :height="height"
    :width="width"
    :alt="locale"
    :class="{ 'circle': circle }"
  ></v-img>
</template>

<script>
export default {
  name: "FlagLocale",
  props: {
    locale: {
      default: null
    },
    width: {
      default: null
    },
    height: {
      default: null
    },
    circle: {
      default: false,
      type: Boolean
    }
  },
  setup(props) {
    return {}
  }
}
</script>

<style lang="scss" scoped>

.v-image {
  &.circle {
    border-radius: 50% !important;
  } 
}
</style>