<template>
  <v-card class="my-4">
    <v-card-text>
      <div class="mb-4">{{ title }}</div>
      <slot></slot>
    </v-card-text>
    <v-card-actions>
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CardSection",
  props: {
    title: String,
    edit: {
      type: Boolean,
      default: false
    },
    editTitle: {
      default: "Editar"
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__actions {
  display: flex;
  justify-content: right;

  button {
    text-transform: capitalize !important;
  }
}
</style>