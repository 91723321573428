var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{},[_c('v-dialog',{attrs:{"persistent":"","max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',{attrs:{"small":"","color":_vm.color}},[_vm._v(" "+_vm._s(_vm.t(_vm.getStatus))+" "),(_vm.isChangeStatus)?_c('v-icon',_vm._g(_vm._b({staticClass:"pl-2",attrs:{"size":"30"}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.icons.mdiCircleEditOutline))]):_vm._e()],1)]}}]),model:{value:(_vm.toggleDialog),callback:function ($$v) {_vm.toggleDialog=$$v},expression:"toggleDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.t("UpdateProject")))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.getStatusProject,"item-value":"key","item-text":"key"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.t(item.key))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.t(item.key))+" ")]}}]),model:{value:(_vm.selectStatus),callback:function ($$v) {_vm.selectStatus=$$v},expression:"selectStatus"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onToggleDialog}},[_vm._v(" "+_vm._s(_vm.t("Close"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!_vm.selectStatus,"loading":_vm.toggleLoading},on:{"click":_vm.changeStatusProjectById}},[_vm._v(" "+_vm._s(_vm.t("Send"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }