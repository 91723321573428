<template>
  <div>
    <div class="m-0" v-if="hasAttachments">
      <p class="subtitle-1"><strong>{{ label }}</strong></p>

      <v-chip
        class="mx-1"
        color="success"
        v-for="(a, index) in _attachments"
        :key="index"
      >
        <span class="file-name">{{ a.name }}</span>

        <v-icon
          color="white"
          @click="onDeleteFile(index, a)"
          class="pl-3"
          v-if="!$attrs.disabled"
          >{{ icons.mdiDeleteCircle }}</v-icon
        >

        <v-icon
          color="white"
          @click="onDownloadFile(a)"
          class="pl-3"
          v-if="!$attrs.disabled"
          >{{ icons.mdiCloudDownload }}</v-icon
        >
      </v-chip>
    </div>

    <v-file-input
      v-else
      accept="application/pdf"
      :label="label"
      :error-messages="errorMessage"
      :rules="rules"
      show-size
      counter
      v-bind="$attrs"
      @change="onInputFile"
      class="p-0 m-0"
    >
      <template v-slot:selection="{ text }">
        <v-chip small label color="primary">
          {{ text }}
        </v-chip>
      </template>
      <template v-slot:counter="{ props }"
        ><span>{{ props.value }}</span></template
      >
    </v-file-input>
  </div>
</template>

<script>
import { watch, ref, computed, onMounted } from "@vue/composition-api";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import { mdiDeleteCircle, mdiCloudDownload } from "@mdi/js";
import api from "@/services";

export default {
  props: {
    label: {
      default: "Adjuntar Archivo .pdf  máximo 2MB",
      type: String,
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    projectVersionId: {
      type: Number,
    },
    nameKey: {
      type: String,
    },
    required: {
      default: true,
      type: Boolean,
    },
  },

  setup(props, { emit }) {
    const { attachments, required, nameKey, projectVersionId } = props;
    const { t } = useUtilsI18n();
    const errorMessage = ref(null);
    const _attachments = ref(attachments.filter((a) => a.key === nameKey) || []);

    const hasAttachments = computed(() => {
      return _attachments.value.length;
    });

    const rules = computed(() => {
      return required && !hasAttachments.value ? [(v) => !!v || ""] : [];
    });

    const fileRules = (file) =>
      (file && file.size > 2e6) || !["application/pdf"].includes(file.type);

    const onInputFile = (file) => {
      errorMessage.value = "";
      fileRules(file)
        ? (errorMessage.value = "Debes seleccionar un archivo PDF (max 2MB)")
        : onAttachFileProjectVersionById(file) ;
    };

    const onDeleteFile = async (indexFile, file) => {
      try {
        _attachments.value.splice(indexFile, 1);
        await api.deleteFileProjectVersionById(file.id);
      } catch (error) {}
    };

    const onDownloadFile = async ({ link }) => {
      try {
        window.open(link, '_blank');
      } catch (error) {}
    };

    const onAttachFileProjectVersionById = async (file) => {
      if (!file) return;
      const formData = new FormData();
      formData.append("key", nameKey);
      formData.append("version_id", projectVersionId);
      formData.append("file", file);

      try {
        const { data } = await api.attachFileProjectVersionById(formData);
        _attachments.value.push(data.data)
      } catch (error) {}
    };

    return {
      errorMessage,
      _attachments,
      hasAttachments,
      rules,

      icons: {
        mdiDeleteCircle,
        mdiCloudDownload,
      },

      onInputFile,
      onDeleteFile,
      onDownloadFile,
      t,
    };
  },
};
</script>

<style lang="scss" scoped>
  .v-file-input {
    margin: 0;
    padding: 0;
  }
  .file-name {
      width: 100%;
      max-width: 230px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
  }
</style>
